@font-face {
  font-family: "GT Pressura";
  src: url("./fonts/GT-Pressura-Regular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "GT Pressura";
  src: url("./fonts/GT-Pressura-Light.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/GT-Pressura-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-Regular.ttf");
  font-weight: 500;
}
