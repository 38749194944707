.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
  background-color: #6b6767 !important;
}

.center-footer {
  place-self: center;
  text-align: -webkit-center;
}

.footer-desktop {
  background-color: $sidebar-color;
}

@include media-breakpoint-down(md) {
  .footer-desktop {
    flex-direction: column;
  }
}

.footer {
  border-top: none;
  padding: 0;
}

.notificacionts {
  align-self: center !important;
  cursor: pointer;
}
